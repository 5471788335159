import React, { useState } from 'react'

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Container from '@mui/material/Container';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@mui/material/Paper';


import ArticlePost from '../components/ArticlePost';
import {articles, classificationCategory, categoryInfo} from '../constants'

const useStyles = makeStyles((theme) => ({
buttonGroup: {
    [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
    },
    display: 'flex',
    justifyContent: 'center',
    marginTop: '20px',
},
}));
  
export function ArticleList({onClickArticle}) {
    const [isDisplayLabel, setIsDisplayLabel] = useState(false);
    const [articlesToShow, setArticlesToShow] = useState(articles);
    const [categoryToShow, setCategoryToShow] = useState(categoryInfo)
    const labels = Object.keys(classificationCategory);
    const classes = useStyles();

    /**
     * handle clicking Analyze Button
     */
    const onClickAnalyzeButton = () => {
        setIsDisplayLabel(true)
    }
    
    /**
     * handle clicking Category Button
     * @param {String} label label to show
     */
    const onClickCategoryButton = (label) => {
        if(label === 'all') {
            setArticlesToShow(articles);
            setCategoryToShow(categoryInfo)
        } else {
            const filteredArticles = articles.filter(article => article.label === label);
            setArticlesToShow(filteredArticles);
            const filteredCategory = categoryInfo.filter(info => info.name === label)
            setCategoryToShow(filteredCategory)
        }
        
    }
    

    return(
            <Container>
                { isDisplayLabel ? (
                    <Stack spacing={2}>
                        <ButtonGroup variant="contained" 
                        aria-label="outlined primary button group" 
                        className={classes.buttonGroup} 
                        style={{ boxShadow: 'none'}}
                        >
                            {labels.map(label => (
                                <Button key={label} 
                                    style={{ width: '100%' }} 
                                    color={classificationCategory[label] || "default"} 
                                    onClick={() => onClickCategoryButton(label)}>
                                    {label}
                                </Button>
                            ))}
                        </ButtonGroup>
                        <Accordion style={{ boxShadow: 'none'}}>
                            <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            >
                            <Typography  sx={{fontWeight: 'bold' }}>Category Info</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell key='tag-name-table-head'>Tag Name</TableCell>
                                            <TableCell key='seed-words-table-head'align="center">Seed Words</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                    {categoryToShow.map((row) => (
                                        <TableRow
                                        key={row.name}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell key={row.name + 'tag-name-table-body'} component="th" scope="row">
                                                {row.name}
                                            </TableCell>
                                            <TableCell key={row.name + 'seed-words-table-body'} align="left">{row.seed_words}</TableCell>
                                        </TableRow>
                                    ))}
                                    </TableBody>
                                </Table>
                                </TableContainer>
                            </AccordionDetails>
                        </Accordion>
                    </Stack>
                ) : (
                    <Container key='Analyze-button-container' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mb: 5 }}>
                        <Button variant="contained" onClick={onClickAnalyzeButton} style={{ background: "#10294b", width: '25%' }}  fullWidth={true}>
                            Analyze
                        </Button>
                    </Container>
                )}
                
                <Container key='articles-container' maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
                    <Grid key='articles-grid' container spacing={2}>
                        {articlesToShow.map((article) => (
                            <Grid key={article.title} onClick={() => onClickArticle(article.id)} xs={12} md={6} lg={4} item>
                                <ArticlePost key={article.title} article={article} isDisplayLabel={isDisplayLabel}/>
                            </Grid>
                        ))}
                    </Grid>
                </Container>
            </Container>
    )
}

export default ArticleList;