import Demo from './page/Demo'
import About from './page/About'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import React, { Component } from 'react';

class App extends Component {
  render() {
    return (
      <Router>
        <div className="App">
          <Routes>
            <Route exact path='/' element={< Demo />}></Route>
            <Route exact path='/about' element={< About />}></Route>
          </Routes>
        </div>
      </Router>
    );
  }
}

export default App;

