import * as React from 'react';
import PropTypes from 'prop-types';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Chip from '@mui/material/Chip';

import { classificationCategory } from '../constants';


export function ArticlePost({article, isDisplayLabel}) {
    const handleArticleClick = () => {
        //TODO: call visualizaiton view with article.id
    }
    
    const getChipColor = () => {
        const label = article.label
        return classificationCategory[label] || "default";
    }
    return (
        <CardActionArea component="a" onClick={handleArticleClick} sx={{ margin: 1 }}>
            <Card sx={{ display: 'flex', flexDirection: 'column' }}>
            {article.image && <CardMedia
                    component="img"
                    sx={{ width: '100%' }}
                    image={article.image}
                    alt={article.imageLabel}
                />}
                <CardContent sx={{ flex: 1 }}>
                    <Box mb={2}>
                        <Typography component="h2" variant="h5">
                            {article.title}
                        </Typography>
                        {isDisplayLabel && (
                        <Chip label={article.label} color={getChipColor()} size="medium" mt="2" />
                        )}
                    </Box>
                    <Box mb={2}>
                        <Typography variant="subtitle1" paragraph>
                            {article.description}
                        </Typography>
                    </Box>
                    <Typography variant="subtitle1" color="primary">
                        See Analysis Result
                    </Typography>
                </CardContent>
                
            
            </Card>
        </CardActionArea>
    );
}

ArticlePost.propTypes = {
    //object of the article to show
    article: PropTypes.object.isRequired
};

export default ArticlePost;