import React from 'react'
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import MainListItems from './ListItems';
import { AppBar } from './AppBar'
import { Drawer } from './Drawer'

const mdTheme = createTheme();
export const Layout = (props) => {
    const { children } = props;
    const [open, setOpen] = React.useState(true);

    /**
     * handle toggle drawer
     */
    const toggleDrawer = () => {
        setOpen(!open);
    };

    return (
        <ThemeProvider theme={mdTheme}>
            <Box sx={{ display: 'flex' }}>
                <CssBaseline />
                <AppBar position="absolute" open={open} style={{ background: "#10294b", paddingTop: '5px' }}>
                    <Toolbar
                        sx={{
                            pr: '24px', // keep right padding when drawer closed
                        }}
                    >
                        <IconButton
                            edge="start"
                            color="inherit"
                            aria-label="open drawer"
                            onClick={toggleDrawer}
                            sx={{
                                marginRight: '36px',
                                ...(open && { display: 'none' }),
                            }}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Typography
                            component="h5"
                            variant="h5"
                            color="inherit"
                            noWrap
                            sx={{ flexGrow: 1, fontWeight: 'bold', margin: '5px' }}

                        >
                            Geospatial Knowledge Hypercube

                        </Typography>
                        <a href="https://iguide.illinois.edu/">
                            <img src="https://iguide.illinois.edu/wp-content/themes/iguide-v2/assets/images/logo-w.png" alt="UIUC Logo" style={{
                                maxWidth: 95,
                                marginRight: '1px',
                                marginTop: '10px'
                            }} />
                        </a>
                    </Toolbar>
                </AppBar>
                <Drawer variant="permanent" open={open}>
                    <Toolbar
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-end',
                            px: [1],
                            paddingTop: '5px',
                        }}
                        style={{ background: "#10294b", height: '69px' }}

                    >
                        <IconButton onClick={toggleDrawer}>
                            <ChevronLeftIcon style={{ color: "white" }} />
                        </IconButton>
                    </Toolbar>
                    <Divider />
                    <List component="nav" >
                        <MainListItems />
                        <Divider sx={{ my: 1 }} />
                    </List>
                </Drawer>
                <Box
                    component="main"
                    sx={{
                        backgroundColor: (theme) =>
                            theme.palette.mode === 'light'
                                ? theme.palette.grey[100]
                                : theme.palette.grey[900],
                        flexGrow: 1,
                        height: '100vh',
                        overflow: 'auto',
                    }}
                >
                    <Toolbar />
                    {children}


                </Box>
            </Box>
        </ThemeProvider >
    );
};

export default Layout;