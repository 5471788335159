import React from 'react'
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import { Copyright } from '../components/Copyright'
import { Layout } from '../components/layout'
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';


function About(props) {
    return (
        <Layout>
            <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>

                <Paper
                    key='aboutBox'
                    sx={{
                        p: 1,
                        display: 'flex',
                        flexDirection: 'column',

                    }}
                    style={{
                        margin: 8,
                        maxHeight: 1000
                    }}
                >
                    <Typography
                        component="h1"
                        variant="h4"
                        color="inherit"
                        noWrap
                        sx={{ flexGrow: 1, m: 1 }}
                    >
                        About
                    </Typography>
                    <Divider variant="middle" />
                    <Typography
                        component="h1"
                        variant="body1"
                        color="inherit"
                        sx={{ flexGrow: 1, m: 1 }}
                    >
                        Today there are massive volumes of text data, including for example news reports, research papers, 
                        and social media. Geospatial knowledge hypercube is defined as a multi-scale structure for integrating 
                        text data with heterogeneous geospatial data to discover latent connections and relationships through 
                        combining a variety of weakly supervised machine learning approaches. The hypercube lays a foundation 
                        for many knowledge discovery applications, such as recognizing geospatial entities and inferring geospatial 
                        relationships.
                    </Typography>
                    <Box
                        component="img"
                        sx={{
                            height: 420,
                            width: 500,
                            maxHeight: { xs: 420, md: 300 },
                            maxWidth: { xs: 500, md: 360 },
                        }}
                        alt="Hypercube diagram"
                        src="https://iguide.illinois.edu/wp-content/uploads/2023/02/hypercube.png"
                    />
                </Paper>
                <Copyright sx={{ pt: 4 }} />
            </Container>
        </Layout >
    )
}

export default About;