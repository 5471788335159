const bufferZoneOptions = {
    fillColor: "coral",
    fillOpacity: 0.3,
    strokeWeight: 2,
    strokeColor: "coral",
    clickable: false,
    editable: true,
    zIndex: 1
};

const sliderMarks = [
    {
        value: 152.4,
        label: '500ft',
    },
    {
        value: 1609.34,
        label: '1mi',
    },
    {
        value: 3218.69,
        label: '2mi',
    },
    {
        value: 4828.03,
        label: '3mi',
    },
];

const containerStyle = {
    width: '100%',
    height: '100%'
};

export { bufferZoneOptions, sliderMarks, containerStyle };