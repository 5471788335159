import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { Grid } from '@mui/material';
import Divider from '@mui/material/Divider';


export default function ResultTable(data) {
    /**
     * make Object for the table row
     * @param {String} type data to show
     * @param {String} name data to show
     * @param {String} latitude data to show
     * @param {String} longitude data to show
     * @returns {Object} 
     */
    const createData = (type, name, latitude, longitude) => {
        return { 'type': type, 'name': name, 'latitude': latitude, 'longitude': longitude };
    }

    /**
     * get location data list for the result table from Json response
     * @param {Object} data Json data response
     * @returns {Array} 
     */
    const getData = (data) => {
        data = data['data']
        let locationList = []
        if (data['LOC'] !== undefined) {
            Object.keys(data['LOC']).forEach(function (key) {
                locationList.push(createData('LOC', key, data['LOC'][key]['lat'], data['LOC'][key]['lng']))
            });
        }
        if (data['FAC'] !== undefined) {
            Object.keys(data['FAC']).forEach(function (key) {
                locationList.push(createData('FAC', key, data['FAC'][key]['lat'], data['FAC'][key]['lng']))
            });
        }
        if (data['ORG'] !== undefined) {
            Object.keys(data['ORG']).forEach(function (key) {
                locationList.push(createData('ORG', key, data['ORG'][key]['lat'], data['ORG'][key]['lng']))
            });
        }
        return locationList
    }

    /**
     * make link to export data and export the data
     * @param {Object} data data to export
     */
    const handleExportButtonClick = (data) => {
        const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(
            JSON.stringify(data)
        )}`;
        const link = document.createElement("a");
        link.href = jsonString;
        link.download = "data.json";

        link.click();
    };

    /**
     * change the input data format to output format
     * @param {Object} data Json data response
     * @returns {Array} 
     */
    const toOutputFormat = (data) => {
        let exportFormat = {}
        data = data['data']
        for (let tag in data) {
            for (let location in data[tag]) {
                let info = data[tag][location]
                let newInfo = {}
                newInfo['latitude'] = info['lat'].toFixed(3)
                newInfo['longitude'] = info['lng'].toFixed(3)
                newInfo['sentences'] = info['from']
                newInfo['tag'] = tag
                exportFormat[location] = newInfo
            }
        }
        return exportFormat
    }

    const rows = getData(data)

    return (
        <Paper
            sx={{
                p: 1,
                display: 'flex',
                flexDirection: 'column',

            }}
            style={{
                margin: 8,
                maxHeight: 500,
            }}
        >

            <Grid container spacing={2}>
                <Grid item xs={10} >
                    <Typography
                        component="h1"
                        variant="h6"
                        color="inherit"
                        noWrap
                        sx={{ flexGrow: 1, m: 1 }}
                    >
                        Geolocation Info
                    </Typography>

                </Grid>
                <Grid item xs={2} container justifyContent="flex-end">
                    <Button variant="contained" onClick={() => handleExportButtonClick(toOutputFormat(data))} style={{ background: "#10294b", height: "35px" }} sx={{ mt: 0.5, mr: 1 }}>Export</Button>
                </Grid>
            </Grid>
            <Divider variant="middle" />
            <Paper
                sx={{
                    p: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    m: 1

                }}
                style={{
                    margin: 8,
                    maxHeight: 300,
                    overflow: 'auto'
                }}
                elevation={0}
            >
                <Table sx={{ minWidth: 650 }} aria-label="simple table" style={{
                    margin: 8,
                    maxHeight: 300,
                    maxWidth: 1000,
                    overflow: 'auto'
                }}>
                    <TableHead>
                        <TableRow>
                            <TableCell key='type-table-cell'>Type</TableCell>
                            <TableCell key='name-table-cell' align="left">Name</TableCell>
                            <TableCell key='latitude-table-cell' align="left">Latitude</TableCell>
                            <TableCell key='longitude-table-cell' align="left">Longitude</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row) => (
                            <TableRow
                                key = {row.name + 'table-body-row'}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell key={row.name + 'type-table-body'} component="th" scope="row">
                                    {row.type}
                                </TableCell>

                                <TableCell key={row.name + 'name-table-body'} align="left">{row.name}</TableCell>
                                <TableCell key={row.name + 'latitude-table-body'} align="left">{Number((row.latitude).toFixed(3))}</TableCell>
                                <TableCell key={row.name + 'longitude-table-body'} align="left">{Number((row.longitude).toFixed(3))}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Paper>
        </Paper >)
}