import React, { useEffect } from 'react'
import { GoogleMap, LoadScript, MarkerF, CircleF } from '@react-google-maps/api';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import Slider from '@mui/material/Slider';
import Button from '@mui/material/Button';
import { bufferZoneOptions, sliderMarks, containerStyle } from "./mapSettings";

import { Modal as ReactModal } from 'react-native';
import { Alert, StyleSheet, Text, Pressable, View } from 'react-native';

const onLoad = (marker) => {
    //donothing
};

export default function Map(props) {
    // Define and set the current map view
    const [mapView, setMapView] = React.useState({
        center: {
            lat: 38,
            lng: -97
        },
        zoom: 4
    })
    const detailedZoomLevel = 9;

    // Define and set the radius of the buffer zone
    const [bufferRadius, setBufferRadius] = React.useState(3218.69); // 2mi
    const minBufferRadius = 152.4; // 500ft
    const maxBufferRadius = 4828.03; // 3mi

    const [indices, setIndices] = React.useState([]);
    const [modalVisible, setModalVisible] = React.useState(false);

    /**
     * set markers to show on the map
     * @param {Array} data data to show
     * @returns {Array} 
     */
    const makeMarker = (data) => {
        data = data['data']
        let markers = []
        let bufferZones = []
        let currIndex = 0

        if (data['LOC'] !== undefined) {
            Object.keys(data['LOC']).forEach(function (key) {
                let pos = { lat: data['LOC'][key]['lat'], lng: data['LOC'][key]['lng'] }
                let thisIndex = currIndex
                currIndex += 2
                markers.push(markers.push(<MarkerF key={key + 'LOC-marker'} onLoad={onLoad} position={pos} icon={
                    "https://maps.google.com/mapfiles/ms/icons/red-dot.png"
                } onClick={() => {
                    props.markerClick(key, 'LOC')
                    setMapView({
                        center: { lat: data['LOC'][key]['lat'], lng: data['LOC'][key]['lng'] },
                        zoom: detailedZoomLevel
                    });
                    let currActiveBufferZoneIndices = indices
                    if (currActiveBufferZoneIndices.includes(thisIndex)) {
                        const indexToBeRemoved = currActiveBufferZoneIndices.indexOf(thisIndex);
                        currActiveBufferZoneIndices.splice(indexToBeRemoved, 2)
                        setIndices(currActiveBufferZoneIndices);
                    } else {
                        setIndices(indices => [...indices, thisIndex, thisIndex + 1]);
                    }
                }} />))
                bufferZones.push(bufferZones.push(<CircleF
                    key={key + 'LOC-buffer'}
                    center={pos}
                    radius={bufferRadius}
                    options={bufferZoneOptions}
                />))
            });
        }

        if (data['FAC'] !== undefined) {
            Object.keys(data['FAC']).forEach(function (key) {
                let pos = { lat: data['FAC'][key]['lat'], lng: data['FAC'][key]['lng'] }
                let thisIndex = currIndex
                currIndex += 2
                markers.push(markers.push(<MarkerF key={key + 'FAC-marker'} onLoad={onLoad} position={pos} icon={
                    "https://maps.google.com/mapfiles/ms/icons/green-dot.png"
                } onClick={() => {
                    props.markerClick(key, 'FAC')
                    setMapView({
                        center: { lat: data['FAC'][key]['lat'], lng: data['FAC'][key]['lng'] },
                        zoom: detailedZoomLevel
                    });
                    let currActiveBufferZoneIndices = indices
                    if (currActiveBufferZoneIndices.includes(thisIndex)) {
                        const indexToBeRemoved = currActiveBufferZoneIndices.indexOf(thisIndex);
                        currActiveBufferZoneIndices.splice(indexToBeRemoved, 2)
                        setIndices(currActiveBufferZoneIndices);
                    } else {
                        setIndices(indices => [...indices, thisIndex, thisIndex + 1]);
                    }
                }} />))
                bufferZones.push(bufferZones.push(<CircleF
                    key={key + 'FAC-buffer'}
                    center={pos}
                    radius={bufferRadius}
                    options={bufferZoneOptions}
                />))
            });
        }

        if (data['ORG'] !== undefined) {
            Object.keys(data['ORG']).forEach(function (key) {
                let pos = { lat: data['ORG'][key]['lat'], lng: data['ORG'][key]['lng'] }
                let thisIndex = currIndex
                currIndex += 2
                markers.push(markers.push(<MarkerF key={key + 'ORG-marker'} onLoad={onLoad} position={pos} icon={
                    "https://maps.google.com/mapfiles/ms/icons/blue-dot.png"
                } onClick={() => {
                    props.markerClick(key, 'ORG')
                    setMapView({
                        center: { lat: data['ORG'][key]['lat'], lng: data['ORG'][key]['lng'] },
                        zoom: detailedZoomLevel
                    });
                    let currActiveBufferZoneIndices = indices
                    if (currActiveBufferZoneIndices.includes(thisIndex)) {
                        const indexToBeRemoved = currActiveBufferZoneIndices.indexOf(thisIndex);
                        currActiveBufferZoneIndices.splice(indexToBeRemoved, 2)
                        setIndices(currActiveBufferZoneIndices);
                    } else {
                        setIndices(indices => [...indices, thisIndex, thisIndex + 1]);
                    }
                }} />))
                // Adjustable circles. Ref: https://codesandbox.io/s/circle-react-google-maps-api-lo9f6
                bufferZones.push(bufferZones.push(<CircleF
                    key={key + 'ORG-buffer'}
                    center={pos}
                    radius={bufferRadius}
                    options={bufferZoneOptions}
                />))
            });
        }

        const selectedBufferZones = indices.map(i => bufferZones[i])
        return [markers, selectedBufferZones]
    }

    let markers = makeMarker(props)[0]
    let bufferZones = makeMarker(props)[1]

    // When the user clicks the highlighted button above the map view, it will recenter the map to the point of interest.
    useEffect(() => {
        if (props.zoom !== undefined) {
            setMapView({
                center: { lat: props['data'][props.zoom.fac][props.zoom.key]['lat'], lng: props['data'][props.zoom.fac][props.zoom.key]['lng'] },
                zoom: detailedZoomLevel
            });
        }
    }, [props]);

    // Stylesheet for the Instructions pop-up window
    const instructionsStyles = StyleSheet.create({
        centeredView: {
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: 22,
        },
        modalView: {
            margin: 20,
            backgroundColor: 'white',
            borderRadius: 20,
            padding: 35,
            alignItems: 'center',
            shadowColor: '#000',
            shadowOffset: {
                width: 0,
                height: 2,
            },
            shadowOpacity: 0.25,
            shadowRadius: 4,
            elevation: 5,
        },
        button: {
            borderRadius: 20,
            padding: 10,
            elevation: 2,
        },
        buttonClose: {
            backgroundColor: '#10294b',
        },
        modalButtonText: {
            color: 'white',
            fontWeight: 'bold',
            textAlign: 'center',
        },
        modalBodyText: {
            marginBottom: '10%',
            textAlign: 'center',
        },
    });

    return (
        <Paper
            sx={{
                p: 1,
                display: 'flex',
                flexDirection: 'column',

            }}
            style={{
                margin: 8,
                maxHeight: 700,
                overflow: 'auto'
            }}
        >
            <Typography
                component="h1"
                variant="h6"
                color="inherit"
                noWrap
                sx={{ flexGrow: 1, m: 1 }}
            >
                Geolocation Map
            </Typography>
            <Divider variant="middle" />
            <Paper
                sx={{
                    p: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    ml: 1,
                    mt: 1,
                    mr: 1,
                    height: 600

                }}
                elevation={0}
            >
                <LoadScript
                    googleMapsApiKey={process.env.REACT_APP_GOOGLE_API_KEY}
                >
                    <GoogleMap
                        mapContainerStyle={containerStyle}
                        center={mapView.center}
                        zoom={mapView.zoom}
                        options={{ streetViewControl: false }}
                    >
                        {markers}
                        {bufferZones}
                    </GoogleMap>
                </LoadScript>

                <Grid container spacing={1} style={{ margin: 1 }} alignItems="center">
                    <Grid item xs={1}>
                        <Typography>
                            <img key="LOC_img" src={"https://maps.google.com/mapfiles/ms/icons/red-dot.png"} alt="LOC" style={{
                                marginRight: '1px',
                                width: '25px',
                                height: '25px'
                            }} />
                            LOC
                        </Typography>
                    </Grid>
                    
                    <Grid item xs={1}>
                        <Typography>
                            <img key="FAC_img" src={"https://maps.google.com/mapfiles/ms/icons/green-dot.png"} alt="FAC" style={{
                                marginRight: '1px',
                                width: '25px',
                                height: '25px'
                            }} />
                            FAC
                        </Typography>
                    </Grid>

                    <Grid item xs={1}>
                        <Typography>
                            <img key="ORG_img" src={"https://maps.google.com/mapfiles/ms/icons/blue-dot.png"} alt="ORG" style={{
                                marginRight: '1px',
                                width: '25px',
                                height: '25px'
                            }} />
                            ORG
                        </Typography>
                    </Grid>

                    <Grid item xs={1}>
                    </Grid>

                    {/* Buffer zone radius slider */}
                    <Grid item xs={5}>
                        <Typography id="input-slider" gutterBottom>
                            Buffer Zone Radius: {(bufferRadius * 0.000621371).toFixed(2)} miles &#40;{bufferRadius.toFixed(0)} m&#41;
                        </Typography>
                        <Grid container spacing={2} alignItems="center">
                            <Grid item xs>
                                <Slider
                                    value={typeof bufferRadius === 'number' ? bufferRadius : minBufferRadius}
                                    onChange={(event, newValue) => setBufferRadius(newValue)}
                                    aria-labelledby="input-slider"
                                    min={minBufferRadius}
                                    max={maxBufferRadius}
                                    marks={sliderMarks}
                                    step={1}
                                />
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={1}>
                    </Grid>

                    {/* Instruction for the buffer zone */}
                    <Grid item xs={2}>
                        <Pressable onPress={() => setModalVisible(true)}>
                            <Button variant="contained" style={{ background: "#ffffff", color: "#10294b", borderColor: "#10294b" }}>Instructions</Button>
                        </Pressable>
                    </Grid>

                    <ReactModal
                        animationType="slide"
                        transparent={true}
                        visible={modalVisible}
                        onRequestClose={() => {
                            Alert.alert('Modal has been closed.');
                            setModalVisible(!modalVisible);
                        }}>
                        <View style={instructionsStyles.centeredView}>
                            <View style={instructionsStyles.modalView}>
                                <Text style={instructionsStyles.modalBodyText}>
                                    Instruction details coming soon...
                                </Text>
                                <Pressable
                                    style={[instructionsStyles.button, instructionsStyles.buttonClose]}
                                    onPress={() => setModalVisible(!modalVisible)}>
                                    <Text style={instructionsStyles.modalButtonText}>Close</Text>
                                </Pressable>
                            </View>
                        </View>
                    </ReactModal>
                </Grid>
            </Paper>
        </Paper>
    )
}
