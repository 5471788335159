import React, { useState } from 'react'
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import ResultTable from '../components/ResultTable';
import Map from '../components/Map/Map';
import { Copyright } from '../components/Copyright'
import { Layout } from '../components/layout'
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import axios from 'axios';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Grid from '@mui/material/Unstable_Grid2';
import Modal from '@mui/material/Modal';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import PropTypes from 'prop-types';

import ArticleList from '../components/ArticleList';
import { articles } from '../constants'

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const theme = createTheme({
    typography: {
        fontSize: 12,
    },
});

function Demo(props) {
    const [isLoaded, setIsLoaded] = useState(false);
    const [geolocation, setGeolocation] = useState();
    const [resultData, setResultData] = useState();
    const [detail, setDetail] = useState(null);
    const [isDetail, setIsDetail] = useState(false);
    const [resultMap, setResultMap] = useState();
    const [openModal, setOpenModal] = useState(false);

    const headers = {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'POST,PATCH,OPTIONS'
    }

    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };


    /**
     * handle requests to backend to analyze the given article
     */
    const onClickArticle = async (id) => {
        // Grab the article from the articles folder
        const textInput = articles[id].text
        try {
            setIsLoaded(true)
            const res = await axios.post(process.env.REACT_APP_BACKEND_URL, { headers: headers, method: 'POST', body: { 'news': textInput } })
            if (res.status === 200) {
                setGeolocation(res.data.geolocation)

                setResultData(res.data.sents)
                setIsLoaded(false)
                if (isObjectEmpty(res.data.geolocation) && !isObjectEmpty(res.data.sents)) {
                    setOpenModal(true)
                }
            }
        } catch (error) {
            setIsLoaded(true);
        }
    }

    /**
     * return the color of the chip
     * @param {String} fac facility name
     * @returns {String} label of the color
     */
    const getChipColor = (fac) => {
        if (fac === 'LOC') {
            return "error"
        } else if (fac === "FAC") {
            return "success"
        } else if (fac === "DATE") {
            return "secondary"
        } else if (fac === "TIME") {
            return "info"
        } else {
            return "primary"
        }
    }

    /**
     * handle maker click event. Set the marker information as the detail
     * @param {String} key facility name
     * @param {String} fac facility name
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const markerClick = (key, fac) => {
        let chipColor = getChipColor(fac)
        let highlightText = []
        geolocation[fac][key]['from'].map(v =>
        (
            highlightText.push(getHighlightedText(v, key))
        ))
        setDetail(<div>
            <Paper
                sx={{
                    p: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    m: 1

                }}
                style={{
                    margin: 8,
                    maxHeight: 300,
                    overflow: 'auto'
                }}
                elevation={0}
            >

                <Stack direction="row" spacing={1}>
                    <ThemeProvider theme={theme}>
                        <Typography variant="h6" sx={{ ml: 1, mt: 0.3, textDecoration: "underline" }} >
                            {key}
                        </Typography>
                    </ThemeProvider>

                    <Chip label={fac} color={chipColor} size="medium" mt="2" />
                </Stack>

                <Box sx={{ ml: 1, mr: 1 }}>
                    {highlightText.map(v =>
                    (
                        <Typography key={v} sx={{ mt: 2, mb: 3 }}>{v}</Typography>
                    ))}
                </Box>
            </Paper>

            <Grid container spacing={1}>
                <Grid xs={6}>
                    <Button key='retry-button' variant="contained" onClick={onClickRetry} style={{ background: "#10294b" }} fullWidth={true}>Retry</Button>
                </Grid>
                <Grid xs={6}>
                    <Button key='all-article-button' variant="contained" onClick={onClickAllArticle} style={{ background: "#10294b" }} fullWidth={true} >Show All Article</Button>
                </Grid>
            </Grid>

        </div>)
        setIsDetail(true)
    }


    /**
     * parse the sentence, if the word is matched with keywords, replace the word with a button element
     * @param {String} text sentence in the article
     * @param {Array} keywords
     * @returns {Array} array of parsed sentence with highlighted buttons
     */
    function getHighlightedButton(text, keywords) {
        let higlight = keywords.map(v => v[0].toLowerCase())
        higlight = higlight.map(v => v.replace(/[<>*()?]/g, "\\$&"))

        let dic = Object.fromEntries(keywords.map(([v, k]) => [v, k]));
        var parts = text.split(new RegExp(`(${higlight.join('|')})`, "gi"));
        return parts.map((part, index) => {
            let buttoncolor = getChipColor(dic[part])
            return (
                <React.Fragment key={index}>
                    {higlight.includes(part.toLowerCase()) ? (
                        <Button
                            key='highlight-button'
                            variant="contained"
                            size="small"
                            color={buttoncolor}
                            style={{ paddingLeft: '12px', paddingRight: '12px', paddingTop: '6px', paddingBottom: '6px', textTransform: 'none' }}
                            sx={{ ml: 0, mr: 0 }}
                            aria-label={part}
                            onClick={() => { onLocClick(dic[part], part) }}>
                            {part}
                            <Typography sx={{ ml: 1.5, fontWeight: 'bold' }}>{dic[part]}</Typography>
                        </Button>
                    ) : (
                        part
                    )}
                </React.Fragment>
            )
        });
    }

    /**
     * parse the sentence, if the word is matched with keywords, highlight the word
     * @param {String} text sentence in the article
     * @param {String} higlight keyword to highlight
     * @returns {Array} array of parsed sentence with the highlighted keyword
     */
    function getHighlightedText(text, higlight) {
        var parts = text.split(new RegExp(`(${higlight})`, "gi"));
        return parts.map((part, index) => (
            <React.Fragment key={index}>
                {part.toLowerCase() === higlight.toLowerCase() ? (
                    <b key="part" style={{ backgroundColor: "#fad220" }}>{part}</b>
                ) : (
                    part
                )}
            </React.Fragment>
        ));
    }

    let resultTable, renderMap, modal
    let resultText = []
    let paper1 = []

    /**
     * handle click the location on the map
     * @param {String} fac facility
     * @param {String} key key for the location
     */
    const onLocClick = (fac, key) => {
        // Because DATE and TIME values are not locational, return
        if (fac === "DATE" || fac === "TIME") {
            return
        }
        let zoom = {
            'fac': fac,
            'key': key
        }
        setResultMap(<Map data={geolocation} markerClick={markerClick} zoom={zoom} />);
    }

    /**
     * handle click the retry button
     */
    const onClickRetry = () => {
        window.location.reload(true)
    }

    /**
     * handle click the all article button
     */
    const onClickAllArticle = () => {
        setIsDetail(false)
    }

    /**
     * check the object is empty or not
     * @param {String} objectName name of the object to check
     * @returns {Boolean} 
     */
    const isObjectEmpty = (objectName) => {
        return JSON.stringify(objectName) === "{}";
    };

    /**
     * handle closing the modal
     * @param {String} objectName name of the object to check
     * @returns {Boolean} 
     */
    const handleCloseModal = () => {
        // do nothing
        setOpenModal(false)
    }

    if (geolocation) {
        resultTable = <ResultTable data={geolocation} />
        if (resultMap !== undefined) {
            renderMap = resultMap
        } else {
            renderMap = <Map data={geolocation} markerClick={markerClick} />
        }
    }

    if (resultData) {
        for (let idx in resultData) {
            // Get the sentence from resultData, and add a blank space after the sentence
            let sent = Object.keys(resultData[idx])[0] + ' '
            // Get the classes from the above sentence
            let classes = Object.values(resultData[idx])[0]
            if (classes.length !== 0) {
                resultText.push(getHighlightedButton(sent, classes))
            } else {
                resultText.push(sent)
            }
        }
    }

    modal = (<Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
    >
        <Box key='no-location-box' sx={modalStyle}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
                NO LOCATION EXTRACTED!
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                There are no valid location entities that can be extracted from this article. Please try with another article.
            </Typography>
        </Box>
    </Modal>)

    // Helper functions and variables for the Tab feature that displays both geoparsing and classification results
    function CustomTabPanel(props) {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box key='custom-tab-panel-box' sx={{ p: 3 }}>
                        <div>{children}</div>
                    </Box>
                )}
            </div>
        );
    }

    CustomTabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.number.isRequired,
        value: PropTypes.number.isRequired,
    };

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }
    // End of helper functions...


    // Show loading bar if still processing
    if (isLoaded) {
        paper1 = []
        paper1.push(<Box key='circular-progress-box' sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 280 }}>
            <CircularProgress />
        </Box>)
    } else {
        paper1 = []
        // If need to show details about a specific marker
        if (isDetail) {
            paper1 = detail
        }
        // If need to show full results for an article
        else if (resultText.length !== 0) {
            paper1.push(
                <Paper
                    key='resultBox'
                    sx={{
                        p: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        m: 1

                    }}
                    style={{
                        margin: 8,
                        maxHeight: 300,
                        overflow: 'auto'
                    }}
                    elevation={0}
                >
                    {/* Options for users: 1. Geoparsing results; 2. classification results */}
                    <Box key='box-container' sx={{ width: '100%' }}>
                        <Box key='tabs-box' sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                                <Tab key='Geoparsing Results' label="Geoparsing Results" {...a11yProps(0)} />
                                <Tab key='Classification Results' label="Classification Results" {...a11yProps(1)} />
                            </Tabs>
                        </Box>
                    </Box>
                    <Box key='custom-tab-panel-box' sx={{ width: '100%' }}>
                        <CustomTabPanel key='custom-tab-panel-first' value={value} index={0}>
                            {/* Display the Geoparsing result */}
                            <Typography style={{ lineHeight: "45px" }} sx={{ mb: 2 }}>{resultText}</Typography>
                        </CustomTabPanel>
                        <CustomTabPanel key='custom-tab-panel-second' value={value} index={1}>
                            {/* Display the classification result */}
                            {/* Showing a placeholder text... the feature is under development */}
                            <Typography style={{ lineHeight: "45px" }} sx={{ mb: 2 }}>This feature is coming soon!</Typography>
                        </CustomTabPanel>
                    </Box>
                </Paper>)
            paper1.push(<Button key='retry-button' variant="contained" onClick={onClickRetry} style={{ background: "#10294b" }}>Article Gallery</Button>)
        } else {
            // Show the article list when the user first enters or refreshes the page
            paper1.push(<ArticleList key='articleList' onClickArticle={onClickArticle} />)
        }
    }

    return (
        <Layout>
            <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
                <Paper
                    key='demoBox'
                    sx={{
                        p: 1,
                        display: 'flex',
                        flexDirection: 'column',

                    }}
                    style={{
                        margin: 8,
                    }}
                >
                    <Container maxWidth="lg" >
                        <Typography
                            component="h1"
                            variant="h6"
                            color="inherit"
                            noWrap
                            sx={{ flexGrow: 1, m: 1, fontWeight: 'bold' }}
                        >
                            Article Gallery
                        </Typography>
                    </Container>
                    <Divider variant="middle" sx={{ mb: 2 }} />
                    {paper1}
                    {modal}
                </Paper>
                {renderMap}
                {resultTable}
                <Copyright sx={{ pt: 4 }} />
            </Container>
        </Layout >
    )
}

export default Demo;