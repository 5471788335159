
export const articles = [
    {
      id:0,
      title: 'Hurricanes Bring Environmental Renewal ',
      description:
        'AP - Along with their destructive force, hurricanes can have beneficial ...',
      image : 'https://www.mlive.com/resizer/rnbtKHn07kia2-iXdo_U3rRCrTE=/500x0/smart/arc-anglerfish-arc2-prod-advancelocal.s3.amazonaws.com/public/RASCDDUU5BHETKBW74UE7BAVLQ.JPG',
      imageLabel: 'image_0',
      label: 'environment',
      text : '',
    },
    {
      id:1,
      title: "Philippine President Says Country Faces Fiscal Crisis",
      date: 'Nov 11',
      description:
        'Philippine president Gloria Arroyo warned that her country is in the midst of a fiscal crisis. ...',
      image : 'https://assets.bwbx.io/images/users/iqjWHBFdfxIU/iOZSgXfIxQbk/v1/-1x-1.jpg',
      imageLabel: 'image_1',
      label: 'economy',
      text: '',
    },
    {
      id:2,
      title: 'Midland-area residents evacuate to high school after dam failure threatens city',
      description:
        'Midland High School served as a refuge site for Midland residents ...',
      image : "https://www.mlive.com/resizer/CbbciE3K3dNWnf4n6bcSNjxbNns=/1280x0/smart/arc-anglerfish-arc2-prod-advancelocal.s3.amazonaws.com/public/CQPCBH2Y3NCFPPKUYYXJSBNZMA.jpg",
      imageLabel: 'image_2',
      label: 'infrastructure',
      text : '',
    },
    {
      id:3,
      title: 'Afghan Army Dispatched to Calm Violence',
      description:
        "KABUL, Afghanistan - Government troops intervened in Afghanistan's ...",
      image : 'https://www.politico.com/dims4/default/9a3eae7/2147483647/strip/true/crop/1160x773+0+0/resize/1920x1280!/quality/90/?url=https%3A%2F%2Fstatic.politico.com%2Fae%2Fc9%2F4f2d81de4d4aaec447c789ad0f9b%2F210824-kabul-airport-afghanistan-gty-773.jpg',
      imageLabel: 'image_3',
      label: 'politics',
      text : '',
    },
    {
      id:4,
      title: 'Hartford Sees $91 Mln in Charley Losses',
      description: "NEW YORK ( Reuters ) - Hartford Financial Services Group Inc. on Tuesday became ...",
      //image : 'https://www.mlive.com/resizer/rnbtKHn07kia2-iXdo_U3rRCrTE=/500x0/smart/arc-anglerfish-arc2-prod-advancelocal.s3.amazonaws.com/public/RASCDDUU5BHETKBW74UE7BAVLQ.JPG',
      imageLabel: 'image_4',
      label: 'economy',
      text : '',
    },
    {
      id:5,
      title: "N. Korea Economic Sanctions 'One Option'",
      description: "Economic sanctions against North Korea are 'one option,' but care is needed ...",
      //image : 'https://www.mlive.com/resizer/rnbtKHn07kia2-iXdo_U3rRCrTE=/500x0/smart/arc-anglerfish-arc2-prod-advancelocal.s3.amazonaws.com/public/RASCDDUU5BHETKBW74UE7BAVLQ.JPG',
      imageLabel: 'image_5',
      label: 'economy',
      text : '',
    },
    {
      id:6,
      title: "Frances Knocks Out Power, Floods Florida",
      description: "Hurricane Frances' wind and water whacked swaths of southern Florida ...",
      //image : 'https://www.mlive.com/resizer/rnbtKHn07kia2-iXdo_U3rRCrTE=/500x0/smart/arc-anglerfish-arc2-prod-advancelocal.s3.amazonaws.com/public/RASCDDUU5BHETKBW74UE7BAVLQ.JPG',
      imageLabel: 'image_6',
      label: 'environment',
      text : '',
    },
    {
      id:7,
      title: "Iran Warns Its Missiles Can Hit Anywhere in Israel",
      description: "TEHRAN ( Reuters ) - A senior Iranian military official said Sunday Israel ...",
      //image : 'https://www.mlive.com/resizer/rnbtKHn07kia2-iXdo_U3rRCrTE=/500x0/smart/arc-anglerfish-arc2-prod-advancelocal.s3.amazonaws.com/public/RASCDDUU5BHETKBW74UE7BAVLQ.JPG',
      imageLabel: 'image_7',
      label: 'politics',
      text : '',
    },
    {
      id:8,
      title: "Stark images show how Michigan dam failure drained lake",
      description: "The failure of the Edenville Dam in Michigan led to subsequent ...",
      //image : 'https://www.mlive.com/resizer/rnbtKHn07kia2-iXdo_U3rRCrTE=/500x0/smart/arc-anglerfish-arc2-prod-advancelocal.s3.amazonaws.com/public/RASCDDUU5BHETKBW74UE7BAVLQ.JPG',
      imageLabel: 'image_8',
      label: 'infrastructure',
      text : '',
    },
    {
      id:9,
      title: "Hurricane Ivan Batters Grand Cayman",
      description: "GEORGE TOWN, Cayman Islands - Hurricane Ivan battered the Cayman Islands ...",
      //image : 'https://www.mlive.com/resizer/rnbtKHn07kia2-iXdo_U3rRCrTE=/500x0/smart/arc-anglerfish-arc2-prod-advancelocal.s3.amazonaws.com/public/RASCDDUU5BHETKBW74UE7BAVLQ.JPG',
      imageLabel: 'image_9',
      label: 'environment',
      text : '',
    },
    {
      id:10,
      title: "Ida's remnants close roads, prompt concerns of dam failure in Pennsylvania",
      description: "The remnants of Hurricane Ida caused heavy rain and flooding in Pennsylvania ...",
      //image : 'https://www.mlive.com/resizer/rnbtKHn07kia2-iXdo_U3rRCrTE=/500x0/smart/arc-anglerfish-arc2-prod-advancelocal.s3.amazonaws.com/public/RASCDDUU5BHETKBW74UE7BAVLQ.JPG',
      imageLabel: 'image_10',
      label: 'infrastructure',
      text : '',
    },
    {
      id:11,
      title: "California Dam Emergency: 5 Dams That Did Fail",
      description: "There were five notable dam failures throughout history. The first ...",
      //image : 'https://www.mlive.com/resizer/rnbtKHn07kia2-iXdo_U3rRCrTE=/500x0/smart/arc-anglerfish-arc2-prod-advancelocal.s3.amazonaws.com/public/RASCDDUU5BHETKBW74UE7BAVLQ.JPG',
      imageLabel: 'image_11',
      label: 'infrastructure',
      text : '',
    },
    {
      id:12,
      title: "Burning of fossil fuels threatens to overwhelm Arctic environment",
      description: "The burning of fossil fuels has contributed to warming in the Arctic ...",
      //image : 'https://www.mlive.com/resizer/rnbtKHn07kia2-iXdo_U3rRCrTE=/500x0/smart/arc-anglerfish-arc2-prod-advancelocal.s3.amazonaws.com/public/RASCDDUU5BHETKBW74UE7BAVLQ.JPG',
      imageLabel: 'image_12',
      label: 'environment',
      text : '',
    },
    {
      id:13,
      title: "US fighter squadron to be deployed in South Korea next month",
      description: "AFP - A squadron of US Air Force F-15E fighters based in Alaska will ...",
      //image : 'https://www.mlive.com/resizer/rnbtKHn07kia2-iXdo_U3rRCrTE=/500x0/smart/arc-anglerfish-arc2-prod-advancelocal.s3.amazonaws.com/public/RASCDDUU5BHETKBW74UE7BAVLQ.JPG',
      imageLabel: 'image_13',
      label: 'politics',
      text : '',
    },
    {
      id:14,
      title: 'New report blames "rare, but not unprecedented" cause for Edenville Dam failure',
      description: 'A new report has identified the "most plausible" cause of the failure ...',
      //image : 'https://www.mlive.com/resizer/rnbtKHn07kia2-iXdo_U3rRCrTE=/500x0/smart/arc-anglerfish-arc2-prod-advancelocal.s3.amazonaws.com/public/RASCDDUU5BHETKBW74UE7BAVLQ.JPG',
      imageLabel: 'image_14',
      label: 'infrastructure',
      text : '',
    },
    {
      id:15,
      title: 'Prado Dam Failure Could Flood Dozens Of OC Communities',
      description: 'The Prado Dam in Orange County, California, is at risk of breaching ...',
      //image : 'https://www.mlive.com/resizer/rnbtKHn07kia2-iXdo_U3rRCrTE=/500x0/smart/arc-anglerfish-arc2-prod-advancelocal.s3.amazonaws.com/public/RASCDDUU5BHETKBW74UE7BAVLQ.JPG',
      imageLabel: 'image_15',
      label: 'infrastructure',
      text : '',
    },
    {
      id:16,
      title: "GOP Urge Bush to Turn Attention From Iraq",
      description: "Nervous Republicans are urging President Bush to unveil a robust second-term ...",
      //image : 'https://www.mlive.com/resizer/rnbtKHn07kia2-iXdo_U3rRCrTE=/500x0/smart/arc-anglerfish-arc2-prod-advancelocal.s3.amazonaws.com/public/RASCDDUU5BHETKBW74UE7BAVLQ.JPG',
      imageLabel: 'image_16',
      label: 'politics',
      text : '',
    },
    {
      id:17,
      title: 'Aging U.S. dams pose risk to thousands living in their shadows',
      description: 'A two-year investigation by the Associated Press (AP) has revealed that aging ...',
      //image : 'https://www.mlive.com/resizer/rnbtKHn07kia2-iXdo_U3rRCrTE=/500x0/smart/arc-anglerfish-arc2-prod-advancelocal.s3.amazonaws.com/public/RASCDDUU5BHETKBW74UE7BAVLQ.JPG',
      imageLabel: 'image_17',
      label: 'infrastructure',
      text : '',
    },
    {
      id:18,
      title: "British Minister to Visit North Korea in September",
      description: "The British government has announced plans to send a top Foreign Office ...",
      //image : 'https://www.mlive.com/resizer/rnbtKHn07kia2-iXdo_U3rRCrTE=/500x0/smart/arc-anglerfish-arc2-prod-advancelocal.s3.amazonaws.com/public/RASCDDUU5BHETKBW74UE7BAVLQ.JPG',
      imageLabel: 'image_18',
      label: 'politics',
      text : '',
    },
  ];

  export const classificationCategory = {
    "infrastructure" : "success",
    "environment" : "error",
    "politics" : "secondary",
    "economy" : "info",
    "all" : 'warning'
  }

  export const categoryInfo = [
    {
      "name" : "infrastructure",
      "seed_words" : "'infrastructure', 'heritage', 'development', 'network', 'structure', 'equipment', 'technology', 'system', 'property', 'dam', 'structures', 'architecture', 'construction', 'education', 'embankment', 'grid', 'facilities', 'security', 'powerhouse', 'sanitation' ..."
    },
    {
      "name" : "environment",
      "seed_words" : "'environment', 'ecosystem', 'wildlife', 'vegetation', 'forest', 'global', 'nature', 'park', 'climate', 'canopy', 'native', 'ecology', 'biodiversity', 'habitats', 'eco', 'hurricane', 'fauna', 'rainforest', 'plant', 'indigenous', 'fragile', 'conservation', 'tree', 'society' ..."
    },
    {
      "name" : "politics",
      "seed_words" : "'politics', 'political', 'politician', 'government', 'elections', 'democracy', 'democratic', 'governing', 'party', 'leadership', 'state', 'election', 'affairs', 'issues', 'voters', 'debate', 'cabinet', 'congress', 'president', 'religion' ..."
    },
    {
      "name" : "economy",
      "seed_words" : "'economy', economic', 'economically', 'financial', 'economical', 'fiscal', 'economics', 'commercial', 'agricultural', 'industrial', 'economies', 'business', 'uneven', 'international', 'entertainment', 'employment', 'impact', 'annual', 'income' ..."
    }
  ]

  // Function to fetch the text content from the file
const fetchTextFromFile = async (filePath) => {
  const response = await fetch(filePath);
  const text = await response.text();
  return text;
}

// Loop through articles to fetch text for each
for (let i = 0; i < articles.length; i++) {
  const article = articles[i];
  fetchTextFromFile(`/assets/articles/${i + 1}.txt`).then((text) => {
    article.text = text;
  });
}

export default articles;