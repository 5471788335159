import * as React from 'react';
import { useState } from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import WaterDamageIcon from '@mui/icons-material/WaterDamage';
import InfoIcon from '@mui/icons-material/Info';
import NumbersIcon from '@mui/icons-material/Numbers';
import { useNavigate } from "react-router-dom";
import {Alert, Modal, StyleSheet, Text, Pressable, View} from 'react-native';


export default function MainListItems() {
    const [modalVisible, setModalVisible] = useState(false);
    const navigate = useNavigate();

    return (<React.Fragment>
        
        <ListItemButton onClick={() => {
            navigate(`/`)
            window.location.reload();
        }}>
            <ListItemIcon>
                <WaterDamageIcon />
            </ListItemIcon>
            <ListItemText primary="Article Gallery" />
        </ListItemButton>

        <ListItemButton onClick={() => {
            navigate(`/about`)
            window.location.reload();
        }}>
            <ListItemIcon>
                <InfoIcon />
            </ListItemIcon>
            <ListItemText primary="About" />
        </ListItemButton>

        <Modal
            animationType="slide"
            transparent={true}
            visible={modalVisible}
            onRequestClose={() => {
                Alert.alert('Modal has been closed.');
                setModalVisible(!modalVisible);
            }}>
            <View style={styles.centeredView}>
                <View style={styles.modalView}>
                    <Text style={styles.modalBodyText}>Current frontend version: v0.3.2</Text>
                    <Pressable
                        style={[styles.button, styles.buttonClose]}
                        onPress={() => setModalVisible(!modalVisible)}>
                        <Text style={styles.modalButtonText}>Close</Text>
                    </Pressable>
                </View>
            </View>
        </Modal>
      
        <Pressable onPress={() => setModalVisible(true)}>
            <ListItemButton>
                <ListItemIcon>
                    <NumbersIcon />
                </ListItemIcon>
                <ListItemText primary="Version" />
            </ListItemButton>
        </Pressable>
        
    </React.Fragment>);
}

const styles = StyleSheet.create({
    centeredView: {
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: 22,
    },
    modalView: {
      margin: 20,
      backgroundColor: 'white',
      borderRadius: 20,
      padding: 35,
      alignItems: 'center',
      shadowColor: '#000',
      shadowOffset: {
        width: 0,
        height: 2,
      },
      shadowOpacity: 0.25,
      shadowRadius: 4,
      elevation: 5,
    },
    button: {
      borderRadius: 20,
      padding: 10,
      elevation: 2,
    },
    buttonClose: {
      backgroundColor: '#10294b',
    },
    modalButtonText: {
      color: 'white',
      fontWeight: 'bold',
      textAlign: 'center',
    },
    modalBodyText: {
      marginBottom: '25%',
      textAlign: 'center',
    },
  });